import React, { Fragment } from "react";
import { connect } from "react-redux";
import get from 'lodash/get';
import classnames from "classnames";
import "./quickLookViewSimilarButton.scss";
import { openViewSimilarProductPanel, setPanelTitle, VIEW_SIMILAR } from "client/cms/actions/index";

const QuickLookViewSimilarButton = ({
  showQLModalWindow,
  isHover = true,
  productId,
  selectedColorName,
  setPanelTitle,
  openViewSimilarProductPanel,
  defaultColorName,
  selectedColorCode,
  isMobilePhone,
  isTablet,
}) => {
  const productData = {
    productId,
    selectedColorName: selectedColorName || defaultColorName
  };

  const openPanel = (productData) => {
    openViewSimilarProductPanel(productData, selectedColorCode);
    setPanelTitle(VIEW_SIMILAR);
  };

  return (
    <Fragment>
      {!isMobilePhone && !isTablet && <div
        className={classnames(
          "quicklook-viewsimilar-button-background",
          { "fade-in-button-background": isHover },
          { "fade-out-button-background": !isHover }
        )}
      />}
      {!isMobilePhone && !isTablet && <div
        className={classnames(
          "product-thumbnail__quicklook_viewsimilar quicklook-viewsimilar-button-over-image"
        )}
      >
        <button
          aria-label="Quick Look"
          role="button"
          className={classnames(
            "quick-look quicklook-viewsimilar-button-bottom-with-background",
            { "fade-in-button": isHover },
            { "fade-out-button": !isHover }
          )}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            showQLModalWindow();
          }}
        >
          Quick Look
        </button>
        <button
          aria-label="View Similar"
          role="button"
          className={classnames(
            "view-similar quicklook-viewsimilar-button-bottom-with-background",
            { "fade-in-button": isHover },
            { "fade-out-button": !isHover }
          )}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            openPanel(productData);
          }}
        >
          View Similar
        </button>
      </div>}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isMobilePhone: get(state, 'device.isMobilePhone'),
    isTablet: get(state, 'device.isTablet'),
  };
};

const mapDispatchToProps = {
  openViewSimilarProductPanel,
  setPanelTitle
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickLookViewSimilarButton);