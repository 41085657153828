export const DEFAULT_PAGE = 1;
export const DEFAULT_SORT_OPTION = 'NEWEST_FIRST';
export const BEST_MATCH = 'BEST_MATCH';
export const DEFAULT_REQUEST_OPTIONS = {
  page: DEFAULT_PAGE,
  sortBy: '',
};
export const PAGE_SIZE = 120;
export const SWATCHES_LIMIT_MOBILE = 3;
export const SWATCHES_LIMIT_TABLET = 4;
export const SWATCHES_LIMIT_DESKTOP = 7;
export const SWATCHES_LIMIT_MOBILE_MODERN = 5;
export const SWATCHES_LIMIT_TABLET_MODERN = 5;
export const SWATCHES_LIMIT_DESKTOP_MODERN = 8;
export const PRE_ORDER_TEXT = 'Pre-Order';
export const SHOW_MORE_COLORS_AVAILABLE_TEXT = 'More Colors Available';
export const ONLY_AT_NM_TEXT = 'Exclusive';
export const MONOGRAM_TEXT = 'Monogram';
export const SORT_BY_TEXT = 'Sort By';
export const PRODUCTS_NOT_AVAILABLE_MESSAGE = 'We currently have no results for';
export const NEW_ARRIVAL_TEXT = 'New Arrival';
export const SOLD_OUT_TEXT = 'SOLD OUT';
export const BRAND_CODE = 'nm';
export const MY_FAVORITES = 'MY_FAVORITES';
export const FAVORITES_NOT_AVAILABLE_MESSAGE = "Sorry, you don't have any favorite items here";
export const PRODUCT_LIST_FILTER_OPTIONS_EMPTY_MESSAGE = 'Sorry, your last search produced no results. Please adjust your selections and try again.';
export const INSTORE_FILTER_EMPTY_PRODUCT_LIST_ERROR_MESSAGE = 'We currently have no results for this search. Try changing the city, state or zip.';
export const PROMOTION_TYPE_113 = '113';
export const IN_STORE = 'In Store';
export const DESIGNER = 'Designer';
export const SIZE = 'Size';
export const CATEGORY = 'Category';
export const COLOR = 'Color';
export const SWATCH_IMAGE_LOAD_TIMEOUT = 5000;
export const CSP = 'csp';
export const SELECT_LOCATION = 'Select a store location';
export const DT_SELECTED_STORE = 'dt_selected_store';
export const DT_FAVORITE_STORE = 'dt_favorite_store';
export const DT_SELECTED_ZIPCODE = 'dt_selected_zipcode';
export const GIFT_WRAP_KEY = 'Gift Wrap';
export const EXP_HOURS_FOR_TWILIO_PROACTIVE = 24;
export const ECO_FRIENDLY = 'Eco-Friendly';
export const FASHIONED_FOR_CHANGE = 'Fashioned for Change';
export const QUICK_LOOK = "Quick Look";
export const GUCCI = "Gucci";
export const CHANEL = "Chanel";
export const VAN_CLEEF_ARPELS = "Van Cleef & Arpels";
export const BVLGARI = "BVLGARI";
export const FREE_PICKUP = 'Free Pickup';
export const FAST_DELIVERY = 'Fast Delivery';
export const FILTER_LABEL = "Filter";
export const SORT_LABEL = "Sort";
export const PRICE = "Price";
export const REGULAR_SALE = 'Regular';
export const ONSALE_SALE = 'On Sale';
export const SALE = 'Sale';
export const SALE_PROMO_TEXT_DELIMITER = '@#@';

export const MVP_FILTER = 'MVP Filter';
export const ALTERNATE_IMAGE = 'alternate image';

export const QL_VS_SUPPRESS_DESIGNER_LIST = [
  GUCCI, CHANEL, BVLGARI, VAN_CLEEF_ARPELS
]

export const MOBILE_FACET_BUTTONS_LIST = [FILTER_LABEL, SORT_LABEL, DESIGNER, SIZE, COLOR, PRICE];
export const LOCAL_STROAGE_RETRIVE_KEYS = ['dt_selected_store', 'PLP_AND_SRP_FILTERS_RECENT_SIZES',
    '_kmsi', 'fpId' , 'fpStoredDate', 'fingerprintExpiration', 'device_key','SRP_SEARCH_RECENT_KEYWORDS']
export const LOCAL_STROAGE_RETRIVE_KEYS_WITH_PREFIX = ['guestUser.', 'CognitoIdentityServiceProvider.', 'internationalCart_', 'recent_designer_']
