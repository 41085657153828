import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { isDesktop } from 'react-device-detect';

// eslint-disable-next-line import/no-cycle
import RenderContentItem from '../renderContentItem';
import './slideShow.scss';

function SlideShow(props) {
  const {
    speedType,
    cmsContentItems,
    type,
    enableAutoPlay,
    trackTags,
    animationSpeed = 500,
    transitionTime = 7000,
    siteTickerUpdate,
  } = props;
  const animationSpeedType = speedType?.split(' ')[2].toLowerCase();

  const [longestTextWidth, setLongestTextWidth] = useState(0);
  const [isSliderLoaded, setIsSliderLoaded] = useState(false);
  const characterSpace = 10;

  useEffect(() => {
    function calculateMaxCharacterCount() {
      let maxCharacterCount = 0; 
    
      document.querySelectorAll('.slick-slide').forEach(slide => {
        const paragraph = slide.querySelector('.rich-text.list p');
        const text = paragraph ? paragraph.textContent : null;
    
        if (text !== null) {
          const characterCount = text.length;
          maxCharacterCount = Math.max(maxCharacterCount, characterCount);
        }
      });
    
      return maxCharacterCount;
    }
  
    setLongestTextWidth(calculateMaxCharacterCount() * characterSpace);
    
  }, []);

  let playSpeed = transitionTime;
  if (animationSpeedType === 'slow') {
    playSpeed = 3000;
  } else if (animationSpeedType === 'fast') {
    playSpeed = 500;
  }

  const settings = {
    autoplaySpeed: playSpeed,
    autoplay: enableAutoPlay,
    arrows: siteTickerUpdate ? isSliderLoaded : false,
    className: type,
    speed: animationSpeed,
    cssEase: 'ease-in-out',
    pauseOnFocus: true,
    pauseOnHover: true,
  };

  const sliderStyle = {
    'max-width': longestTextWidth > 0 && (longestTextWidth > 1600 ? 1600 : longestTextWidth),
    margin: '0 auto', 
  };

  const handleSliderInit = () => {
    setIsSliderLoaded(true);
  };

  return (
  <div className="slider-container" style={(siteTickerUpdate && isDesktop) ? sliderStyle : null}>
    <Slider {...settings} onInit={handleSliderInit}>
      { cmsContentItems.map((contentItem) => (
        <RenderContentItem
          trackTags={trackTags}
          cmsContentItem={contentItem}
          key={contentItem.sys.id}
        />
      ))}
    </Slider>
  </div>
  );
}

const mapStateToProps = (state) => ({
  enableAutoPlay: get(state, 'page.location.query.ENABLE_AUTOPLAY', 'true') === 'true',
  siteTickerUpdate: get(state, 'toggles.NMO_ADA_HEADER', false),
});

export default connect(mapStateToProps)(SlideShow);
